/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

/*----- Components -----*/
import GenericConfirm from '../../../../commons/confirm/GenericConfirm';
import GenericConfirmCard from '../../../../commons/confirm/GenericConfirm/GenericConfirmCard';

/*----- Utils -----*/
import { activitiesSteps } from '../../../../../utilities/utils';
import { getCheckoutActivity } from '../../../../../services/ActivityCheckoutService';
import { getId } from '../../../../commons/checkout/CheckoutUtils';
import { showActivityData } from '../../../selectors/showActivitySelector';
import { ItemCheckoutConfirmContainer } from '../../../../../components/containers/CheckoutContainer';
import CheckoutConfirmList from '../../../../../components/list/checkoutConfirmList/CheckoutConfirmList';
import { useTranslation } from 'react-i18next';
import ConfirmDetailsCard from '../../../../commons/confirm/ConfirmDetailsCard';
import detailsItemsActivity from '../../checkout/section/detailsItemsActivity';

const SuccessResponse = ({ history, showActivity }) => {
	const { t } = useTranslation();

	const id = getId(history);
	const dataCheckout = getCheckoutActivity(id);

	let details = showActivity.rate.amount_details.occupancy;
	let passengersRequired = details.required;

	return (
		<GenericConfirm
			steps={activitiesSteps}
			id={id}
			active={3}
			ConfirmCard={
				<GenericConfirmCard
					header={
						<ConfirmDetailsCard
							type={t('activities.singleTitle')}
							name={showActivity.name}
							items={detailsItemsActivity(t)}
							dateFrom={dataCheckout.reservation_date_in}
						/>
					}
					service="activities"
					dataCheckout={dataCheckout}
					passengersRequired={passengersRequired}
					taxes={details.passenger.iva}
					sale={details.passenger.sale}
					currency={showActivity.rate.currency}
				>
					<ItemCheckoutConfirmContainer>
						<CheckoutConfirmList
							title={t('checkout.activities.meetingPoint.confirm')}
							list={[
								{
									label: t('checkout.activities.meetingPoint.field.title'),
									value: dataCheckout.hotel
								}
							]}
						/>
					</ItemCheckoutConfirmContainer>
				</GenericConfirmCard>
			}
		/>
	);
};

const mapStateToProps = state => {
	return {
		showActivity: showActivityData(state)
	};
};

export default connect(mapStateToProps, null)(withRouter(SuccessResponse));
