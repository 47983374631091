import TransfersIcon from '../../../../../assets/icons/checkout/transfer.svg';
import PersonIcon from '../../../../../assets/icons/custom_icons/user.svg';

const detailsItemsActivity = (t, adults = '2', children = '') => {
	const childText =
		children > 1 ? t('general.children_plural') : t('general.child_singular');
	const childrenText = children
		? `${t('general.and')} ${children} ${childText}`
		: '';

	return [
		{
			icon: PersonIcon,
			text: `${adults} ${t('general.adults')} ${childrenText}`
		},
		{
			icon: TransfersIcon,
			text: t('general.transfer')
		}
	];
};

export default detailsItemsActivity;
