/*----- Core -----*/
import React from 'react';
import PropTypes from 'prop-types';

/*----- Commons -----*/
import CardCheckoutData from '../../../../commons/checkout/CardCheckoutData';
import detailsItemsActivity from './detailsItemsActivity';
import { useTranslation } from 'react-i18next';
import { getCheckoutActivity } from '../../../../../services/ActivityCheckoutService';

const CheckoutActivityData = ({ id, showActivity, fromCart }) => {
	const { t } = useTranslation();
	const dataCheckout = getCheckoutActivity(id);
	let details = showActivity.rate.amount_details.occupancy;
	return (
		<CardCheckoutData
			image={showActivity.images[0]}
			type={t('activities.singleTitle')}
			name={showActivity.name}
			dateFrom={
				fromCart
					? showActivity?.reservation_date_in
					: dataCheckout?.reservation_date_in
			}
			currency={showActivity.rate.currency}
			totalPrice={details.passenger.sale + details.passenger.iva}
			items={detailsItemsActivity(
				t,
				details.required.adults_number,
				details.required.children_ages.length
			)}
			fromCart={fromCart}
		/>
	);
};
export default CheckoutActivityData;

/*----- PropTypes -----*/

CheckoutActivityData.propTypes = {
	history: PropTypes.object.isRequired,
	showActivity: PropTypes.object.isRequired,
	fromCart: PropTypes.bool
};
