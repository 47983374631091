import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	getDetailCheckout,
	getHotelInformation
} from '../../../hotels/views/checkout/section/detailsItemsHotel.js';
import { capitalize } from '../../../../utilities/utils';
import { ItemCheckoutConfirmContainer } from '../../../../components/containers/CheckoutContainer';
import CheckoutConfirmHeader from '../../../tranfers/views/confirm/section/CheckoutConfirmHeader';
import ConfirmDetailsCard from '../../../commons/confirm/ConfirmDetailsCard';
import detailsItemsActivity from '../../../activities/views/checkout/section/detailsItemsActivity';
import detailsItemsPackages from '../../../packages/views/detail/section/detailsItemsPackages.js';
import moment from 'moment';

const CartDetailItem = ({ item }) => {
	const { t } = useTranslation();
	switch (item.type) {
		case 'transfer':
			return (
				<ItemCheckoutConfirmContainer>
					<CheckoutConfirmHeader
						type={`${item.info.kind.toUpperCase()} | ${capitalize(
							item.info.category.name.toLowerCase()
						)}`}
						title={item.name}
						dateIn={
							item.reservation_date_in &&
							moment(item.reservation_date_in, 'DD/MM/YYYY').format('DD/MM/YYYY')
						}
						dateOut={
							item.reservation_date_out &&
							moment(item.reservation_date_out, 'DD/MM/YYYY').format('DD/MM/YYYY')
						}
					/>
				</ItemCheckoutConfirmContainer>
			);
		case 'excursion':
			let details = item.rate.amount_details.occupancy;
			return (
				<ConfirmDetailsCard
					type={t('activities.singleTitle')}
					name={item.name}
					items={detailsItemsActivity(
						t,
						details.required.adults_number,
						details.required.children_ages.length
					)}
					dateFrom={item.reservation_date_in}
				/>
			);
		case 'package':
			const itemsList = item.searchQuery.with_hotel
				? item.itemsDetails
				: detailsItemsPackages(false, item.searchQuery);

			return (
				<ConfirmDetailsCard
					name={item.name}
					dateFrom={item.searchQuery.date_in}
					dateTo={item.searchQuery.date_out}
					items={itemsList}
					type={t(
						`packages.package${
							item.searchQuery.with_hotel ? 'WithHotel' : 'WithoutHotel'
						}`
					)}
				/>
			);
		case 'hotel':
		default:
			const hotelInformation = getHotelInformation(item, item.rate.item_id);
			return (
				<ConfirmDetailsCard
					type={t('hotels.hotel')}
					name={item.name}
					items={getDetailCheckout(hotelInformation, ['regime', 'passengers'])}
					dateFrom={item.searchQuery.date_in}
					dateTo={item.searchQuery.date_out}
				/>
			);
	}
};

export default CartDetailItem;
