/*----- Core -----*/
import React, { useState } from 'react';
import { connect } from 'react-redux';

/*----- Data -----*/
import moment from 'moment';

/*----- Components -----*/
import {
	StyledRow,
	StyledDetailCol,
	StyledReservationCol
} from '../../../../commons/detail/styles';
import StepsContainer from '../../../../../components/steps/StepsContainer';
import DetailHeader from '../../../../commons/detail/DetailHeader';
import ReservationPanelActivity from '../../../../../components/panel/ReservationPanelActivity';
import DetailTitle from '../../../../commons/detail/detailTitle';
import DetailDescription from '../../../../commons/detail/DetailDescription';

import { activitiesSteps, isMobile } from '../../../../../utilities/utils';
import { getId } from '../../../../commons/checkout/CheckoutUtils';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

import { showActivityData } from '../../../selectors/showActivitySelector';
import detailsItemsActivity from '../../checkout/section/detailsItemsActivity';
import SelectDateActivity from './SelectDateActivity';
import { getCheckoutActivity } from '../../../../../services/ActivityCheckoutService';

const SuccessResponse = ({ history, showActivity }) => {
	const { t } = useTranslation();

	const occupancy = showActivity.rate.amount_details.occupancy;

	let activityCheckout = getCheckoutActivity(getId(history));
	let defaultDate = activityCheckout
		? moment(activityCheckout.reservation_date_in)
		: null;

	const [reservationDate, setReservationDate] = useState(defaultDate);

	return (
		<>
			<StepsContainer active={1} rate_id={1} dataSteps={activitiesSteps()} />
			<DetailHeader images={showActivity.images} />

			<StyledRow type="flex">
				<StyledDetailCol xs={24} md={16} lg={18}>
					<DetailTitle title={showActivity.name} />
					{showActivity.info.observations[1].text ? (
						<DetailDescription
							description={showActivity.info.observations[1].text}
						/>
					) : null}
					<SelectDateActivity
						reservationDate={reservationDate}
						setReservationDate={setReservationDate}
					/>
				</StyledDetailCol>
				<StyledReservationCol xs={24} md={8} lg={6}>
					<ReservationPanelActivity
						amount={occupancy.passenger.sale + occupancy.passenger.iva}
						currency={showActivity.currency}
						goCheckout={() => history.push(`/activities/${getId(history)}/checkout`)}
						disabled={isMobile() && !reservationDate}
						itemsDetails={detailsItemsActivity(
							t,
							occupancy.required.adults_number,
							occupancy.required.children_ages.length
						)}
					/>
				</StyledReservationCol>
			</StyledRow>
		</>
	);
};

const mapStateToProps = state => {
	return {
		showActivity: showActivityData(state)
	};
};

export default connect(mapStateToProps, null)(withRouter(SuccessResponse));
